<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <card class="mr-2 shadow" body-classes="standard-card-body">
        <div class="d-flex">
          <div class="flex-grow-1 mr-3">
            <base-input class="mb-0">
              <el-date-picker
                type="month"
                v-model="dateSelected"
                value-format="yyyy-MM"
                format="MM/yyyy"
                :placeholder="$t('common.select_month')"
              />
            </base-input>
          </div>
          <div class="ml-auto">
            <base-button link class="text-uppercase" @click="reloadData">
              {{ $t('balance.update_preview') }}
            </base-button>
          </div>
          <div class="ml-3">
            <base-button link class="text-uppercase" @click="openModalCheckCommission">
              {{ $t('balance.start_royalty_computing') }}
            </base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-12">
      <tabs centered square>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('balance.leads_with_appointment') }}</div>
            </div>
          </template>
          <commission-leads-with-appointment-datatable
            :key="`commission-leads-with-appointment-datatable-${reloadKey}`"
            :month="monthSelected" :year="yearSelected" />
        </tab-pane>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('balance.deals_closed') }}</div>
            </div>
          </template>
          <commission-deals-positive-closed-datatable
            :key="`commission-deals-positive-closed-datatable-${reloadKey}`"
            :month="monthSelected" :year="yearSelected" />
        </tab-pane>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('balance.deals_revenue') }}</div>
            </div>
          </template>
          <commission-deals-competence-date-datatable
            :key="`commission-deals-competence-date-datatable-${reloadKey}`"
            :month="monthSelected" :year="yearSelected" />
        </tab-pane>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('balance.deals_royalties') }}</div>
            </div>
          </template>
          <commission-deals-confirmed-datatable
            :key="`commission-deals-confirmed-datatable-${reloadKey}`"
            :month="monthSelected" :year="yearSelected" />
        </tab-pane>
        <tab-pane>
          <template slot="label">
            <div class="d-flex align-items-center">
              <div class="text-uppercase m-3">{{ $t('balance.enrollments') }}</div>
            </div>
          </template>
          <commission-deals-enrollment-datatable
            :key="`commission-deals-enrollment-datatable-${reloadKey}`"
            :month="monthSelected" :year="yearSelected" />
        </tab-pane>
      </tabs>
    </div>
    <modal centered bodyClasses="p-1" :show.sync="showModalCheckCommission">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('balance.check_commission_locking') }}
      </h5>
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="$t('balance.permissions.deadline')">
          <template slot="value">
            <octo-icon
              :class="commissionPermissions.deadline ? 'text-success' : 'text-muted'"
              :icon="commissionPermissions.deadline ? 'check' : 'cancel'"/>
          </template>
        </list-group-item-component>

        <list-group-item-component
          :label="$t('balance.permissions.prevGeneration')"
        >
          <template slot="value">
            <octo-icon
              class="text-success"
              v-if="commissionPermissions.prevGeneration"
              icon="check"/>
            <base-button
              v-if="!commissionPermissions.prevGeneration"
              @click="deletePrevCommissions"
              :confirmRequired="true"
              :confirmTitle="$t('balance.confirm_delete_commissions')"
              link
              class="text-uppercase">
              {{ $t('balance.remove_prev_commissions') }}
            </base-button>
          </template>
        </list-group-item-component>
      </ul>
      <template slot="footer">
        <base-button link class="text-uppercase"
                     :disabled="!commissionPermissions.deadline || !commissionPermissions.prevGeneration"
                     :confirmRequired="true"
                     :confirmTitle="$t('balance.confirm_start_generation')"
                     @click="generateCommissions">
          {{ $t('balance.start_generation') }}
        </base-button>
      </template>
    </modal>
    <modal centered bodyClasses="p-1" :show.sync="showModalProgressCommission">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('balance.commissions_loading') }}
      </h5>
      <commission-progress-jobs :key="`commission-progress-jobs-${modalProgressCommissionKey}`"/>
    </modal>
  </div>
</template>

<script>
import {DatePicker} from "element-ui";
import {TabPane, Tabs} from "@/components";
import CommissionLeadsWithAppointmentDatatable
  from "@/pages/Balances/datatables/CommissionLeadsWithAppointmentDatatable";
import CommissionDealsPositiveClosedDatatable from "@/pages/Balances/datatables/CommissionDealsPositiveClosedDatatable";
import CommissionDealsCompetenceDateDatatable from "@/pages/Balances/datatables/CommissionDealsCompetenceDateDatatable";
import CommissionDealsConfirmedDatatable from "@/pages/Balances/datatables/CommissionDealsConfirmedDatatable";
import CommissionDealsEnrollmentDatatable from "@/pages/Balances/datatables/CommissionDealsEnrollmentDatatable";
import Modal from "@/components/Modal";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CommissionProgressJobs from "@/pages/Balances/components/CommissionProgressJobs";

export default {
  name: "PreviewPage",
  components: {
    CommissionProgressJobs,
    OctoIcon,
    ListGroupItemComponent,
    Modal,
    CommissionDealsEnrollmentDatatable,
    CommissionDealsConfirmedDatatable,
    CommissionDealsCompetenceDateDatatable,
    CommissionDealsPositiveClosedDatatable,
    CommissionLeadsWithAppointmentDatatable,
    [DatePicker.name]: DatePicker,
    Tabs,
    TabPane,
  },
  data() {
    return {
      dateSelected: this.$moment().subtract(1, 'months').format('YYYY-MM'),
      reloadKey: 1,
      showModalCheckCommission: false,
      showModalProgressCommission: false,
      modalProgressCommissionKey: 1,
      commissionPermissions: {
        deadline: false,
        prevGeneration: false
      },
    }
  },
  computed: {
    monthSelected: function () {
      return this.dateSelected?.split('-')[1] || null;
    },
    yearSelected: function () {
      return this.dateSelected?.split('-')[0] || null;
    }
  },
  mounted() {

  },
  methods: {
    reloadData: function () {
      this.reloadKey++;
    },
    openModalCheckCommission: function () {
      this.commissionPermissions.deadline = false;
      this.commissionPermissions.prevGeneration = false;
      this.$fullLoading.show();
      this.$api.get(endpoints.COMMISSIONS_CHECK_GENERATION_PERMISSION
        .replace('{month}', this.monthSelected)
        .replace('{year}', this.yearSelected))
        .then((data) => {
          this.showModalCheckCommission = true;
          this.commissionPermissions.deadline = data?.data?.deadline || false;
          this.commissionPermissions.prevGeneration = data?.data?.prevGeneration || false;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })

    },
    deletePrevCommissions: async function () {
      try {
        this.$fullLoading.show();
        await this.$api.delete(endpoints.COMMISSIONS_DELETE_BY_MONTH
          .replace('{month}', this.monthSelected)
          .replace('{year}', this.yearSelected)
        );
        const resp = await this.$api.get(endpoints.COMMISSIONS_CHECK_GENERATION_PERMISSION
          .replace('{month}', this.monthSelected)
          .replace('{year}', this.yearSelected)
        );
        this.commissionPermissions.deadline = resp?.data?.deadline || false;
        this.commissionPermissions.prevGeneration = resp?.data?.prevGeneration || false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      } finally {
        this.$fullLoading.hide();
      }


    },
    generateCommissions: function () {
      this.showModalCheckCommission = false;
      this.modalProgressCommissionKey++;
      this.showModalProgressCommission = true;
      this.$api.get(endpoints.COMMISSIONS_GENERATE
        .replace('{month}', this.monthSelected)
        .replace('{year}', this.yearSelected))
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.commission_job_start')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.commission_job_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
