<template>
      <ul class="list-group list-group-flush">
        <list-group-item-component
          v-for="(job, index) in jobs" v-bind:key="`jobs-${index}`"
          :label="job.label">
          <template slot="value">
            <octo-icon v-if="job.waiting" class="text-muted" icon="hourglass" />
            <octo-icon v-if="!job.waiting"
                       :class="job.success ? 'text-success' : 'text-danger'"
                       :icon="job.success ? 'check' : 'cancel'" />

          </template>
        </list-group-item-component>
      </ul>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {mapGetters} from "vuex";
export default {
  name: "CommissionProgressJobs",
  components: {OctoIcon, ListGroupItemComponent},
  data() {
    return {
      jobs: {
        generateCommissionsAppointmentAwards: {
          label: this.$t("balance.jobs.generateCommissionsAppointmentAwards"),
          waiting: true,
          success:false
        },
        generateCommissionsDealAwards: {
          label: this.$t("balance.jobs.generateCommissionsDealAwards"),
          waiting: true,
          success:false
        },
        generateCommissionsDealForManagersAwards: {
          label: this.$t("balance.jobs.generateCommissionsDealForManagersAwards"),
          waiting: true,
          success:false
        },
        generateCommissionsPersonalThresholdAwards: {
          label: this.$t("balance.jobs.generateCommissionsPersonalThresholdAwards"),
          waiting: true,
          success:false
        },
        generateCommissionsGroupThresholdAwards: {
          label: this.$t("balance.jobs.generateCommissionsGroupThresholdAwards"),
          waiting: true,
          success:false
        },
        generateCommissionsEnrollments: {
          label: this.$t("balance.jobs.generateCommissionsEnrollments"),
          waiting: true,
          success:false
        },
        generateCommissionsDeals: {
          label: this.$t("balance.jobs.generateCommissionsDeals"),
          waiting: true,
          success:false
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      broadcastEvent: 'common/broadcastEvent',
    }),

  },

  mounted() {
    this.$_.each(this.jobs, (job) => {
      job.waiting = true;
      job.success = false;
    })
  },

  watch: {
    broadcastEvent: function (val) {
      if (this.jobs.hasOwnProperty(val.message)) {
        this.jobs[val.message].waiting = false;
        this.jobs[val.message].success = val.mode === 'success';
      }
    }
  }

}
</script>

<style scoped>

</style>
