<template>
  <div>
    <octo-table
      v-if="month && year"
      :action="datatableUrl"
      :fields="fields"
      :filters="filters"
      :name="name"
      enable-export
      enable-export-email
      show-filters
      :show-filters-collapse="false"
      show-query-input
      @onResetFilters="filters = {}"
    >
      <template v-slot:name="data">
        <div class="text-uppercase small">
          {{ data.row.surname}} {{ data.row.name}}
        </div>
      </template>

      <template v-slot:sales="data">
        <div class="small text-uppercase">
          {{ users[data.row.user_id] | optional('lastname') }}
          {{ users[data.row.user_id] | optional('firstname') }}
        </div>
      </template>

      <template v-slot:status="data">
        <badge :type="data.row.status | dealStatusColor" v-if="data.row.status" class="text-uppercase">
          {{ $t('deal.status.' + data.row.status) }}
        </badge>
      </template>

      <template v-slot:competence_date="data">
        <div class="small">
          {{ data.row.competence_date | date}}
        </div>
      </template>

      <template v-slot:contract_amount="data">
        <div class="small">
          {{ data.row.contract_amount | euro}}
        </div>
      </template>


      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip content="Dates" :open-delay="300" placement="top">
            <base-button class="mx-1"
                         size="sm"
                         link
                         icon
            >
              <octo-icon icon="right-arrow"/>
            </base-button>
          </el-tooltip>
        </div>
      </template>
    </octo-table>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {mapGetters} from "vuex";
import {Select, Option, DatePicker} from "element-ui";

export default {
  name: "CommissionDealsCompetenceDateDatatable",
  components: {
    Badge,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      name: 'CommissionDealsCompetenceDateDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'name', label: 'name', slot: true, align: 'left'},
        {prop: 'sales', label: 'sales', slot: true, align: 'left'},
        {prop: 'status', label: 'deal_status', slot: true, align: 'center'},
        {prop: 'competence_date', label: 'date_competence', slot: true, align: 'center'},
        {prop: 'contract_amount', label: 'amount', slot: true, align: 'center'},
      ],
    }
  },
  props: {
    month: {
      type: String,
      default: null
    },
    year: {
      type: String,
      default: null
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      users: 'common/users'
    }),

    startDate () {
      if(!this.month || !this.year) {
        return null;
      }
      const data = this.$moment(this.year + '-' + this.month, 'YYYY-MM').startOf('month')

      return data.format('DD/MM/YYYY')
    },

    endDate () {
      if(!this.month || !this.year) {
        return null;
      }
      const data = this.$moment(this.year + '-' + this.month, 'YYYY-MM').endOf('month')

      return data.format('DD/MM/YYYY')
    },

    datatableUrl () {
      return endpoints.DATATABLES.commissionDealsCompetenceDateMonth
        .replace('{month}', this.month)
        .replace('{year}', this.year)
    }

  }
}
</script>

<style scoped>

</style>
